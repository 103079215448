import React, { Component } from 'react';
import './App.css';
import Getgoogle from "./Getgoogle";

function App() {
  return (
    <div className="App">
      {/* <header className="App-header"> */}
        <Getgoogle />
      {/* </header> */}
    </div>
  )

}



// import React, { Component } from 'react';

// class App extends Component {
//   state = { users: []}
  
//   componentDidMount() {
//     fetch('https://jun-jung-proxy.herokuapp.com/https://jun-jung-db.herokuapp.com')
//     .then(res => res.json())
//     .then(users => this.setState({ users }));
//   }


//   render() {
//     return (
//       <div className="App">
//         <h1>Users</h1>
//         <ul>
//           {this.state.users.map(user =>
//             <li key={user.id}>{user.username}</li>
//             )}
//         </ul>
//       </div>
//     );
//  }
// }


export default App;