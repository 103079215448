import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import Google from "./Google";




ReactDOM.render(
  <React.StrictMode>
    <div><App /></div>
  </React.StrictMode>,
  document.getElementById('root')
);
