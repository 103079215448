import React, { Component } from "react";
import axios from 'axios';

export default class Getgoogle extends Component {
  constructor() {
    super();
    this.state = {
      captions: [],
      category: ["title-d", "description-d", "spec-d"]
    };
  }

  

  componentDidMount = () => {
    axios.get("https://jun-jung-proxy.herokuapp.com/https://jun-jung-db.herokuapp.com").then(response => {
      this.setState({
        captions: response.data.values
      }); 
    });

    
  };


  render() {
    
    return (
      <div id="captions">
        {this.state.captions.map((project, i) => (
          <div key={`project-${i}`} id={`p-${i}`} >
            {project.map((content, i) => (
              <div key={`content-${i}`} className={this.state.category[i]}>
                {content}
              </div>
            ))}
          </div>
        ))}

      </div>
    );
  }

}



// export default Getgoogle;

// import React, {useState, useEffect} from 'react';

// function Users() {
//   const [users, setUsers] = useState([]);

//   useEffect(() => {
//     fetch("/users/").then(res => {
//       if(res.ok) {
//         return res.json()
//       }
//     }).then(jsonRes => setUsers(jsonRes.usersList))
//   })

//   return (<div>
//     {users.map(user => <li>{user}</li>)}
//   </div>)

  
// }




